/* eslint-disable */
export default [
  // Account setup routes/
  {
    path: '/setup/welcome',
    name: 'setup-welcome',
    component: () => import('@/views/app/account/setup/Welcome.vue'),
    meta: {
      page_group: 'setup',
      layout: 'full',
      resource: 'm_dashboard',

      action: 'read',
    },
  },
  {
    path: '/setup/not-verified',
    name: 'not-verified',
    component: () => import('@/views/app/account/setup/NotVerified.vue'),
    meta: {
      page_group: 'setup',
      layout: 'full',
      resource: 'm_dashboard',

      action: 'read',
    },
  },

  {
    path: '/setup/business-details',
    name: 'setup-business-details',
    component: () => import('@/views/app/account/setup/wizard/SetupWizard.vue'),
    meta: {
      page_group: 'setup',
      layout: 'full',
      resource: 'm_dashboard',
      action: 'read',
    },
  },
  // End account setup routes
  // Aauth routes start

  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/app/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/app/auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/app/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/app/auth/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // auth routes end

  // *=============DASHBOARD=============
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/app/dashboard/Dashboard.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
    },
  },
  // *=============PRODUCTS=============
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/app/product/ProductList.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
      pageTitle: 'Products',
    },
  },
  {
    path: '/products/add',
    name: 'products-add',
    component: () => import('@/views/app/product/ProductEdit.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
    },
  },
  {
    path: '/products/edit/:id',
    name: 'products-edit',
    component: () => import('@/views/app/product/ProductEdit.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
    },
  },
  // *=============CLIENTS=============
  {
    path: '/clients',
    name: 'clients',
    component: () => import('@/views/app/client/ClientList.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
      pageTitle: 'Clients',
    },
  },
  {
    path: '/clients/add',
    name: 'clients-add',
    component: () => import('@/views/app/client/ClientEdit.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
    },
  },
  {
    path: '/clients/edit/:id',
    name: 'clients-edit',
    component: () => import('@/views/app/client/ClientEdit.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
    },
  },

  {
    path: '/clients/show/:id',
    name: 'clients-show',
    component: () => import('@/views/app/client/show/ClientShow.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
    },
  },
  // *=============INVOICES=============

  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/app/invoice/InvoiceList.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
      pageTitle: 'Invoices',
    },
  },
  {
    path: '/invoices/add',
    name: 'invoices-add',
    component: () => import('@/views/app/invoice/InvoiceEdit.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
      layout: 'full',
    },
  },
  {
    path: '/invoices/edit/:id',
    name: 'invoices-edit',
    props: true,
    component: () => import('@/views/app/invoice/InvoiceEdit.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
      layout: 'full',
    },
  },
  // *=============INVOICES=============

  {
    path: '/recurring-invoices',
    name: 'recurring-invoices',
    component: () => import('@/views/app/recurring-invoice/InvoiceList.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
      pageTitle: 'Recurring Invoices',
    },
  },
  {
    path: '/recurring-invoices/add',
    name: 'recurring-invoices-add',
    component: () => import('@/views/app/recurring-invoice/InvoiceEdit.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
      layout: 'full',
    },
  },
  {
    path: '/recurring-invoices/edit/:id',
    name: 'recurring-invoices-edit',
    props: true,
    component: () => import('@/views/app/recurring-invoice/InvoiceEdit.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
      layout: 'full',
    },
  },
  // reports
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/app/report/Reports.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
      pageTitle: 'Reports',
    },
  },
  // end reports
  // *=============SUPPORTS=============
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/app/support/TicketList.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
    },
  },
  {
    path: '/support/messages/:id',
    name: 'support-messages',
    component: () => import('@/views/app/support/Messages.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- MY SETTINGS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/app/account/settings/AccountSetting.vue'),
    meta: {
      resource: '*',
      action: 'read',
    },
  },
  {
    path: '/settings/invoice-design',
    name: 'settings-invoice-design',
    component: () => import('@/views/app/account/settings/invoice/InvoiceDesign.vue'),
    meta: {
      layout: 'full',
      resource: '*',
      action: 'read',
    },
  },
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: () => import('@/views/app/auth/ConfirmEmail.vue'),
    meta: {
      layout: 'full',
      authOrNoAuth: true,
    },
  },
  // others
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/app/others/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  // Subscription
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import('@/views/app/account/subscription/Subscription.vue'),
    meta: {
      layout: 'full',
      resource: '*',
      action: 'read',
    },
  },
]
